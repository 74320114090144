/* eslint-disable max-lines */
import { split } from 'lodash';

interface DateRange {
  startDate: string;
  endDate: string;
}

export const dateForInputFormat = (date: Date) => {
  if (!date) return null;

  const offset = date.getTimezoneOffset();

  date = new Date(date.getTime() - offset * 60 * 1000);

  return date.toISOString().split('T')[0];
};

export const parseFrenchDate = (dateString: string) => {
  const [dd, mm, yyyy] = dateString.split('/');

  return new Date([yyyy, mm, dd].join('-'));
};

export const addMinutes = (date: Date, duration: number): Date => {
  const dateClone = new Date(date.getTime());
  return new Date(dateClone.setMinutes(dateClone.getMinutes() + duration));
};

export const addHours = (date: Date, duration: number): Date => {
  const dateClone = new Date(date.getTime());
  return new Date(dateClone.setHours(dateClone.getHours() + duration));
};

export const addDays = (date: Date, duration: number): Date => {
  return addHours(date, duration * 24);
};

export const addWeeks = (date: Date, weeks: number): Date => {
  const dateClone = new Date(date.getTime());
  return new Date(dateClone.setDate(dateClone.getDate() + weeks * 7));
};

export const addMonths = (date: Date, duration: number): Date => {
  const dateClone = new Date(date.getTime());
  return new Date(dateClone.setMonth(dateClone.getMonth() + duration));
};

export const isToday = ({ getDate, getMonth, getFullYear }: Date) => {
  const {
    getDate: currentDate,
    getMonth: currentMonth,
    getFullYear: currentFullYear,
  } = new Date();

  return (
    getDate === currentDate &&
    getMonth == currentMonth &&
    getFullYear == currentFullYear
  );
};

export const addYears = (date: Date, duration: number): Date => {
  return new Date(date.setMonth(date.getFullYear() + duration));
};

export const getIsoDate = (date: Date): string =>
  new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split('T')[0];

export const getTimestampToDays = (timestamp: number): number =>
  timestamp / (1000 * 3600 * 24);

export const getNilSafeDate = (date?: string): Date | null | undefined => {
  if (date === 'null') return null;

  return typeof date === 'string' ? new Date(date) : date;
};

export const getEmploymentDuration = (employmentDate: string): number => {
  return Math.floor(
    getTimestampToDays(Date.now()) -
      getTimestampToDays(Date.parse(employmentDate)),
  );
};

// ex: 28/02/2023
export const formatDate = (date: string | Date): string =>
  date ? new Date(date).toLocaleDateString('fr-FR') : null;

// ex: 28/02
export const formatDayAndMonth = (date: string | Date): string =>
  date
    ? new Date(date).toLocaleDateString('fr-FR', {
        day: '2-digit',
        month: '2-digit',
      })
    : null;

// ex: 28/02/2023 15:28
export const formatShortDateWithHour = (date: Date): string => {
  if (!date) return null;

  const time = new Date(date).toLocaleTimeString('fr-FR').split(':');
  return `${formatDate(date)} ${time[0]}:${time[1]}`;
};

// ex: 28/02/2023 à 15:28
export const formatShortDateWithHourAndLinkWord = (date: Date): string => {
  if (!date) return null;

  const time = new Date(date).toLocaleTimeString('fr-FR').split(':');
  return `${formatDate(date)} à ${time[0]}:${time[1]}`;
};

// ex: jeudi 28 février 2023 à 15:28
export const formatDateWithHour = (rawDate: Date | number | string): string => {
  const date = typeof rawDate === 'string' ? new Date(rawDate) : rawDate;

  return new Intl.DateTimeFormat('fr-FR', {
    dateStyle: 'full',
    timeStyle: 'short',
  }).format(date);
};

// ex: jeu. 11 déc. 2023, 15:28
export const formatDateWithHourLowercase = (
  rawDate: Date | number | string,
): string => {
  const date = typeof rawDate === 'string' ? new Date(rawDate) : rawDate;

  return new Intl.DateTimeFormat('fr-FR', {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }).format(date);
};

// ex: 28 février 2023
export const formatLongDate = (rawDate: Date | number | string): string => {
  const date = typeof rawDate === 'string' ? new Date(rawDate) : rawDate;

  return new Intl.DateTimeFormat('fr-FR', {
    dateStyle: 'long',
  }).format(date);
};

// ex: 02-28 -> 28 février
export const formatDateWithDayAndMonth = (date: string): string => {
  const [month, day] = split(date, '-');

  const now = new Date();
  now.setMonth(parseInt(month) - 1);

  return `${day} ${now.toLocaleString('fr-FR', { month: 'long' })}`;
};

// ex: 2024-12-31 -> 31 décembre
export const formatDateWithDayAndMonthBis = (dateString: string): string => {
  const date = new Date(dateString);

  const dateFormatter = new Intl.DateTimeFormat('fr-FR', {
    day: 'numeric',
    month: 'long',
  });

  return dateFormatter.format(date);
};

// ex: janv. 2023
export const formatDateWithMonthAndYear = (rawDate: string | Date): string => {
  const date = typeof rawDate === 'string' ? new Date(rawDate) : rawDate;

  return new Intl.DateTimeFormat('fr-FR', {
    year: 'numeric',
    month: 'short',
  }).format(date);
};

// ex: janv. 2023
export const formatDateWithDayAndShortMonth = (
  rawDate: string | Date,
): string => {
  const date = typeof rawDate === 'string' ? new Date(rawDate) : rawDate;

  return new Intl.DateTimeFormat('fr-FR', {
    day: 'numeric',
    month: 'short',
  }).format(date);
};

export const getLatestMonths = (
  count: number,
): Array<{ value: number; label: string }> => {
  const months = [];

  for (let idx = 0; idx <= count; idx++) {
    const now = new Date();
    const timestamp = now.setMonth(now.getMonth() - idx);

    const date = new Intl.DateTimeFormat('fr-FR', {
      month: 'long',
    }).format(timestamp);

    months.push({
      value: new Date(timestamp).getMonth() + 1,
      label: date.charAt(0).toUpperCase() + date.slice(1),
    });
  }

  return months.reverse();
};

export const isCurrentYear = (date: Date): boolean => {
  return new Date(date).getFullYear() === new Date().getFullYear();
};

export const isAfter = (date1: Date, date2: Date): boolean =>
  new Date(date1).getTime() >= new Date(date2).getTime();

export const isBefore = (date1: Date, date2: Date): boolean =>
  new Date(date1).getTime() <= new Date(date2).getTime();

export const isSameDay = (first: Date, second: Date): boolean => {
  if (!first || !second) return false;

  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  );
};

export const getYearsSince = (from: number): Array<number> => {
  const years = [];
  const now = new Date().getFullYear();

  for (let index = from; index <= now; index++) {
    years.push(from);

    from++;
  }

  return years.reverse();
};

export const getMonthFirstDay = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const getMonthLastDay = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

export const getMonthDifference = (startDate: Date, endDate: Date) => {
  return (
    endDate.getMonth() -
    startDate.getMonth() +
    12 * (endDate.getFullYear() - startDate.getFullYear())
  );
};

export const getDayDifference = (startDate: Date, endDate: Date): number => {
  const diffTime = Math.abs(endDate.getTime() - startDate.getTime());

  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const getDateRanges = (): {
  late: DateRange;
  today: DateRange;
  yesterday: DateRange;
  currentWeek: DateRange;
  currentMonth: DateRange;
  currentYear: DateRange;
  lastMonth: DateRange;
  lastYear: DateRange;
  inOneMonth: DateRange;
  inTwoMonths: DateRange;
} => {
  const date = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(date.getDate() + 1);
  const yesterday = new Date();
  yesterday.setDate(date.getDate() - 1);

  // CURRENT
  const firstDayCurrentMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDayCurrentMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0,
  );

  const firstDayCurrentYear = new Date(date.getFullYear(), 0, 1);
  const lastDayCurrentYear = new Date(date.getFullYear(), 11, 31);

  // current week
  const currentDay = date.getDay(); // 0: Sunday, 1: Monday ...

  const startWeekDate = new Date(date);
  startWeekDate.setDate(date.getDate() - currentDay + 1);

  const endWeekDate = new Date(startWeekDate);
  endWeekDate.setDate(startWeekDate.getDate() + 6);

  // Next months
  const firstDayInOneMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    1,
  );
  const lastDayInOneMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 2,
    0,
  );

  const firstDayInTwoMonths = new Date(
    date.getFullYear(),
    date.getMonth() + 2,
    1,
  );
  const lastDayInTwoMonths = new Date(
    date.getFullYear(),
    date.getMonth() + 3,
    0,
  );

  // LAST
  const firstDayLastMonth = new Date(
    date.getFullYear(),
    date.getMonth() - 1,
    1,
  );
  const lastDayLastMonth = new Date(date.getFullYear(), date.getMonth(), 0);

  const firstDayLastYear = new Date(date.getFullYear() - 1, 0, 1);
  const lastDayLastYear = new Date(date.getFullYear() - 1, 11, 31);

  return {
    late: {
      startDate: getIsoDate(new Date('2015-01-01')),
      endDate: getIsoDate(date),
    },
    today: {
      startDate: getIsoDate(date),
      endDate: getIsoDate(tomorrow),
    },
    yesterday: {
      startDate: getIsoDate(yesterday),
      endDate: getIsoDate(date),
    },
    currentWeek: {
      startDate: getIsoDate(startWeekDate),
      endDate: getIsoDate(endWeekDate),
    },
    currentMonth: {
      startDate: getIsoDate(firstDayCurrentMonth),
      endDate: getIsoDate(lastDayCurrentMonth),
    },
    currentYear: {
      startDate: getIsoDate(firstDayCurrentYear),
      endDate: getIsoDate(lastDayCurrentYear),
    },
    lastMonth: {
      startDate: getIsoDate(firstDayLastMonth),
      endDate: getIsoDate(lastDayLastMonth),
    },
    lastYear: {
      startDate: getIsoDate(firstDayLastYear),
      endDate: getIsoDate(lastDayLastYear),
    },
    inOneMonth: {
      startDate: getIsoDate(firstDayInOneMonth),
      endDate: getIsoDate(lastDayInOneMonth),
    },
    inTwoMonths: {
      startDate: getIsoDate(firstDayInTwoMonths),
      endDate: getIsoDate(lastDayInTwoMonths),
    },
  };
};

// gets the number of a given week (e.g. for 2023-12-31, it returns 52)
export const getWeekNumber = (date: Date) => {
  // Copy date so don't modify original
  const copy = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
  );

  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  copy.setUTCDate(copy.getUTCDate() + 4 - (copy.getUTCDay() || 7));

  // Get first day of year
  const yearStart = new Date(Date.UTC(copy.getUTCFullYear(), 0, 1));

  // Calculate full weeks to nearest Thursday
  const weekNumber = Math.ceil(
    ((copy.getTime() - yearStart.getTime()) / 86400000 + 1) / 7,
  );

  // Return week number
  return weekNumber;
};

export const getMonday = (date: Date) => {
  date = new Date(date);
  const day = date.getDay(),
    diff = date.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(date.setDate(diff));
};

export const getNumberOfDaysOnWeekOnMonthForDate = (date: Date) => {
  let numberOfDays = 0;

  const month = date.getMonth();

  let dateClone = new Date(date.getTime());
  let day = dateClone.getDay();
  // if it's sunday, then count as 7
  day = day === 0 ? 7 : day;

  // stop if we arrived to monday or we changed month
  while (day >= 1 && dateClone.getMonth() === month) {
    numberOfDays += 1;

    // go to yesterday
    dateClone = addDays(dateClone, -1);
    day = dateClone.getDay();
  }

  return numberOfDays;
};

export const getHoursAndMinutesFromSeconds = (
  initialSeconds: number,
  shouldDisplaySeconds = false,
) => {
  const hours = Math.floor(initialSeconds / 3600);
  const minutes = Math.floor((initialSeconds % 3600) / 60);
  const seconds = Math.round(initialSeconds % 60);

  let ret = '';

  ret += hours + ':' + (minutes < 10 ? '0' : '');
  ret += minutes;

  if (shouldDisplaySeconds) {
    ret += ':' + seconds;
  }

  return ret;
};

export const getHoursAndMinutesFromSecondsWithAbbreviations = (
  initialSeconds: number,
) => {
  const hours = Math.floor(initialSeconds / 3600);
  const minutes = Math.floor((initialSeconds % 3600) / 60);
  const seconds = Math.round(initialSeconds % 60);

  let ret = '';

  const displayedHours = hours > 0 ? hours + 'h' : '';

  ret += displayedHours + (minutes < 10 ? '0' : '');
  ret += minutes + 'm';
  ret += seconds;
  return ret;
};

export const getDayNumber = (date: Date) => {
  return (
    (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) -
      Date.UTC(date.getFullYear(), 0, 0)) /
    24 /
    60 /
    60 /
    1000
  );
};
