import { makeAutoObservable } from 'mobx';
import { BolderEntity } from 'v2.api/src/entities/bolder.entity';

class Bolder {
  value: BolderEntity = null;

  constructor() {
    makeAutoObservable(this);
  }

  setValue(bolder: BolderEntity): void {
    this.value = bolder;
  }
}

export { Bolder };

export default new Bolder();
