import Cookies from 'js-cookie';
import { IntercomProps } from 'react-use-intercom';

export const shouldInitializeIntercom = (): boolean => {
  return (
    process.env.NEXT_PUBLIC_ENV === 'production' &&
    !Cookies.get('admin_access_token')
  );
};

const tryUpdate = (
  update: (options: Partial<IntercomProps>) => void,
  options: Partial<IntercomProps>,
): void => {
  if (!shouldInitializeIntercom()) return;

  update(options);
};

export { tryUpdate };
