import api from 'helpers/api';
import { BolderCompanyUpdateBody } from 'v2.api/src/bolders/dto/bolder-company-update-body.dto';
import { BolderCreateBody } from 'v2.api/src/bolders/dto/bolder-create-body.dto';
import { BolderUpdateBody } from 'v2.api/src/bolders/dto/bolder-update-body.dto';
import { Sort } from 'v2.api/src/common-generic/enum';
import { toQueryString } from 'v2.api/src/common-generic/query-string';
import { BolderEntity } from 'v2.api/src/entities/bolder.entity';
import { BoldersEntity } from 'v2.api/src/entities/bolders.entity';
import { ClientUserCompaniesEntity } from 'v2.api/src/entities/client-user-companies.entity';
import { CompanyEntity } from 'v2.api/src/entities/company.entity';
import { ContractTypeEntity } from 'v2.api/src/entities/contract-type.entity';

export const getPaginatedBolders = async (query?: {
  page?: string;
  search?: string;
  teamIds?: string;
  statusIds?: string;
  subTeamIds?: string;
  pageSize?: string;
  withOperationCount?: boolean;
  sortKey?: string;
  sort?: Sort;
}) => {
  const { data } = await api<{ bolders: Array<BolderEntity>; count: number }>({
    method: 'get',
    url: `/v2/bolders${toQueryString(query)}`,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
  });

  return data;
};

export const getAllBolders = async (query?: {
  shouldExcludeResigned?: boolean;
  shouldExcludeToOnboard?: boolean;
}) => {
  const {
    data: { bolders },
  } = await api<BoldersEntity>({
    method: 'get',
    url: `/v2/bolders/all${toQueryString(query)}`,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
  });

  return bolders;
};

export const getBoldersByTeam = async (teamId: string) => {
  const {
    data: { bolders },
  } = await api<BoldersEntity>({
    method: 'get',
    url: `/v2/bolders/teams/${teamId}/bolders`,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
  });

  return bolders;
};

export const getBoldersBySubTeam = async (subTeamId: string) => {
  const {
    data: { bolders },
  } = await api<BoldersEntity>({
    method: 'get',
    url: `/v2/bolders/teams/sub-teams/${subTeamId}/bolders`,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
  });

  return bolders;
};

export const getBolderByEmail = async (email: string) => {
  const { data } = await api<BolderEntity>({
    method: 'get',
    url: `/v2/bolders${toQueryString({ email })}`,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
  });

  return data;
};

export const getBolder = async (id: string) => {
  const { data } = await api<BolderEntity>({
    method: 'get',
    url: `/v2/bolders/${id}`,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
  });

  return data;
};

export const getClientUserCompaniesAsBolder = async (query?: {
  page?: string;
  status?: string;
  search?: string;
  company?: string;
  pageSize?: string;
  sortKey?: string;
  sort?: Sort;
}) => {
  const { data } = await api<ClientUserCompaniesEntity>({
    method: 'get',
    url: `/v2/bolders/client-user-companies${toQueryString(query)}`,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
  });

  return data;
};

export const getCompaniesAsBolder = async (query?: {
  search?: string;
  page?: string;
  pageSize?: string;
  sortKey?: string;
  sort?: Sort;
  status?: string;
  verticalIds?: string;
  customerManagerId?: string;
  lastFollowUp?: string;
  nextFollowUp?: string;
  activity?: string;
  sectorIds?: string;
  businessModelIds?: string;
  headOfLegalId?: string;
  website?: string;
  contact?: string;
}) => {
  const { data } = await api<{
    count: number;
    totalMrr: number;
    activeSubscriptionCount: number;
    companies: Array<CompanyEntity>;
  }>({
    method: 'get',
    url: `/v2/bolders/companies${toQueryString(query)}`,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
  });

  return data;
};

export const getCompanyAsBolder = async (id: string) => {
  const { data } = await api<CompanyEntity>({
    method: 'get',
    url: `/v2/bolders/companies/${id}`,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
  });

  return data;
};

export const updateCompanyAsBolder = async (
  id: string,
  body: BolderCompanyUpdateBody & { display_name: string },
) => {
  const { data } = await api<CompanyEntity>({
    method: 'put',
    url: `/v2/bolders/companies/${id}`,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
    body,
  });

  return data;
};

export const updateBolder = async (
  bolderId: string,
  body: Partial<BolderEntity>,
) => {
  const { data } = await api<BolderEntity>({
    method: 'put',
    url: `/v2/bolders/${bolderId}`,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
    body,
  });

  return data;
};

export const updateBolderPersonalInfo = async (
  bolderId: string,
  body: Partial<BolderUpdateBody>,
) => {
  const { data } = await api<BolderEntity>({
    method: 'put',
    url: `/v2/bolders/${bolderId}/personal-information`,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
    body,
  });

  return data;
};

export const updateBolderAvailability = async (
  bolderId: string,
  body: Partial<BolderEntity>,
) => {
  const { data } = await api<BolderEntity>({
    method: 'put',
    url: `/v2/bolders/${bolderId}/availability`,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
    body,
  });

  return data;
};

export const updateBolderLevelRelations = (
  bolderId: string,
  levels: Array<string>,
) =>
  api({
    method: 'put',
    url: `/v2/bolders/${bolderId}/levels`,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
    body: { levels },
  });

export const createBolder = async (body: BolderCreateBody) => {
  const { data } = await api<BolderEntity>({
    method: 'post',
    url: '/v2/bolders',
    body,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
  });

  return data;
};

export const getContractTypes = async () => {
  const { data } = await api<Array<ContractTypeEntity>>({
    method: 'get',
    url: '/v2/bolders/contract-types',
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
  });

  return data;
};
