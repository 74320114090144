import { makeAutoObservable } from 'mobx';

class Fireworks {
  isVisible = false;

  constructor() {
    makeAutoObservable(this);
  }

  setIsVisible(isVisible: boolean): void {
    this.isVisible = isVisible;
    if (this.isVisible) {
      setTimeout(() => {
        this.isVisible = false;
      }, 3000);
    }
  }
}

export { Fireworks };

export default new Fireworks();
