import accessControlMatrix from 'observables/access-control-matrix';
import bolderAccessControlMatrix from 'observables/bolder-access-control-matrix';
import { getBolderAccessControlMatrices } from 'queries/auth';

export const fetchBolderAccessControlMatrices = async () => {
  try {
    accessControlMatrix.setIsLoading(true);
    const matrices = await getBolderAccessControlMatrices();

    bolderAccessControlMatrix.setBolderAcms(matrices);
  } finally {
    accessControlMatrix.setIsLoading(false);
  }
};
