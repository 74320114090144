import { makeAutoObservable } from 'mobx';
import { ClientUserEntity } from 'v2.api/src/entities/client-user.entity';

class ClientUser {
  value: ClientUserEntity = null;

  constructor() {
    makeAutoObservable(this);
  }

  setValue(clientUser: ClientUserEntity): void {
    this.value = clientUser;
  }
}

export { ClientUser };

export default new ClientUser();
