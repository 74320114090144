import find from 'lodash/find';
import { makeAutoObservable } from 'mobx';
import { AccessControlMatrix as AccessControlMatrixType } from 'types/access-control-matrix';
import { Resources } from 'types/resources';

class AccessControlMatrix {
  entries: Array<AccessControlMatrixType> = [];
  isAcmLoading = true;

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading(isLoading: boolean): void {
    this.isAcmLoading = isLoading;
  }

  setAcms(entries: Array<AccessControlMatrixType>): void {
    this.entries = entries;
  }
}

const accessControlMatrix = new AccessControlMatrix();

const getResourceMatrix = (resourceName: Resources): AccessControlMatrixType =>
  find(accessControlMatrix.entries, { name: resourceName }) ?? {
    name: '',
    description: '',
    rights: '',
    is_cross_companies: false,
  };

export { getResourceMatrix };
export default accessControlMatrix;
