import Cookies from 'js-cookie';

const logOut = (): void => {
  Cookies.remove('access_token');
  Cookies.remove('admin_access_token');
  Cookies.remove('role');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('redirect_url');
};

export default logOut;
