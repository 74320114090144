import { Toaster } from 'react-hot-toast';

const ToasterProvider = (): React.ReactNode => (
  <Toaster
    position="bottom-center"
    reverseOrder={false}
    toastOptions={{
      duration: 5000,
      style: {
        margin: '10px',
        borderRadius: '6px',
        backgroundColor: '#E5E7EB',
      },
      success: {
        style: {
          backgroundColor: '#34D399',
          color: '#fff',
        },
        iconTheme: {
          primary: '#059669',
          secondary: '#fff',
        },
      },
      error: {
        style: {
          backgroundColor: '#F87171',
          color: '#fff',
        },
        iconTheme: {
          primary: '#DC2626',
          secondary: '#fff',
        },
      },
    }}
  />
);

export default ToasterProvider;
