import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { DecodedSessionToken } from 'types/decoded-token';

import { getEmailWithoutPrefix } from './email';

const getToken = (): string => Cookies.get('access_token') || null;

const getDecodedSessionToken = <T = DecodedSessionToken>(token: string): T =>
  jwtDecode(token) as T;

const getUserFromToken = (
  existingToken?: string,
): Partial<DecodedSessionToken> => {
  const token = existingToken || getToken();

  if (!token) return {};

  const { email, ...rest } = getDecodedSessionToken(token);

  return { ...rest, email: getEmailWithoutPrefix(email) };
};

export { getToken, getDecodedSessionToken, getUserFromToken };
