import * as Sentry from '@sentry/react';
import { jwtDecode } from 'jwt-decode';

export const getEmailWithoutPrefix = (email: string): string => {
  const emailWithoutBolderPrefix = email.replace(/^bolder\+/, '');
  const emailWithoutAdminAndBolderPrefix = emailWithoutBolderPrefix.replace(
    /^admin\+/,
    '',
  );

  return emailWithoutAdminAndBolderPrefix;
};

export const getEmailFromToken = (token?: string) => {
  if (!token) return null;

  try {
    const decodedToken = jwtDecode<{ email: string }>(token);

    if (decodedToken.email.endsWith('@wearebold.co')) {
      return getEmailWithoutPrefix(decodedToken.email);
    }

    return decodedToken.email;
  } catch (error) {
    Sentry.captureException(error);
    return null;
  }
};
