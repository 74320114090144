import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import split from 'lodash/split';

import { QueryObject } from './types/query-object';

export const toQueryString = (queryObject: QueryObject): string => {
  const filteredQuery = compact(
    map(
      queryObject,
      (value, key) => value && [key, encodeURIComponent(value)].join('='),
    ),
  );

  if (isEmpty(filteredQuery)) return '';

  return `?${filteredQuery.join('&')}`;
};

export const getParsedQueryString = (
  queryString: string,
): Record<string, string | number | boolean> => {
  const elements = split(queryString, '&');

  return reduce(
    elements,
    (acc, element) => {
      const [key, value] = split(element, '=');

      return { ...acc, [key]: value };
    },
    {},
  );
};
