import { Fireworks as FireworksLib } from '@fireworks-js/react';

const Fireworks = () => {
  return (
    <FireworksLib
      options={{
        hue: {
          min: 0,
          max: 345,
        },
        delay: {
          min: 15,
          max: 15,
        },
        rocketsPoint: { min: 50, max: 50 },
        traceSpeed: 10,
        acceleration: 1.2,
        friction: 0.96,
        gravity: 1,
        particles: 90,
        traceLength: 3,
        explosion: 6,
        autoresize: true,
        brightness: {
          min: 50,
          max: 80,
        },
        decay: {
          min: 0.015,
          max: 0.03,
        },
        sound: {
          enabled: true,
          files: [
            'https://fireworks.js.org/sounds/explosion0.mp3',
            'https://fireworks.js.org/sounds/explosion1.mp3',
            'https://fireworks.js.org/sounds/explosion2.mp3',
          ],
          volume: {
            min: 1,
            max: 2,
          },
        },
        mouse: {
          click: true,
          move: false,
          max: 1,
        },
      }}
      style={{
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        position: 'fixed',
        zIndex: 50,
      }}
    />
  );
};

export default Fireworks;
