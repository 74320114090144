import find from 'lodash/find';
import { makeAutoObservable } from 'mobx';
import { BolderResources } from 'v2.api/src/common-business/types/bolder-resources';
import { BolderAccessControlMatrixEntity } from 'v2.api/src/entities/bolder-access-control-matrix.entity';

class BolderAccessControlMatrix {
  entries: Array<BolderAccessControlMatrixEntity> = [];
  isAcmLoading = null;

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading(isLoading: boolean): void {
    this.isAcmLoading = isLoading;
  }

  setBolderAcms(entries: Array<BolderAccessControlMatrixEntity>): void {
    this.entries = entries;
  }
}

const bolderAccessControlMatrix = new BolderAccessControlMatrix();

const getBolderResourceMatrix = (
  resourceName: BolderResources,
): BolderAccessControlMatrixEntity =>
  find(
    bolderAccessControlMatrix.entries,
    ({ bolderResource: { name } }) => name === resourceName,
  );

export { getBolderResourceMatrix };

export default bolderAccessControlMatrix;
