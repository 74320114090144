const sentryDsn = {
  development:
    'https://d2748fea07fa4c0ba99ae0864b17925b@o375557.ingest.sentry.io/5640813',
  staging:
    'https://fb1bd922804e4b18914885681b12f8cc@o375557.ingest.sentry.io/5640779',
  production:
    'https://7f01a6f1aa6a4e059427f7f5617658a4@o375557.ingest.sentry.io/5640817',
};

export { sentryDsn };
