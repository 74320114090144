import api from 'helpers/api';
import { AccessControlMatrix } from 'types/access-control-matrix';
import { toQueryString } from 'v2.api/src/common-generic/query-string';
import { BolderAccessControlMatrixEntity } from 'v2.api/src/entities/bolder-access-control-matrix.entity';
import { BolderEntity } from 'v2.api/src/entities/bolder.entity';
import { OperationEventTypesPrivilegesEntity } from 'v2.api/src/entities/operation-event-types-privileges.entity';
import { PrivilegesEntity } from 'v2.api/src/entities/privileges.entity';

export const getHmac = async () => {
  const {
    data: { hmac, name },
  } = await api<{ hmac: string; name: string }>({
    method: 'get',
    url: '/v2/authentication/hmac',
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
  });

  return { hmac, name };
};

export const isEmailKnown = async (email: string) => {
  const { data } = await api<{ isEmailKnown: boolean }>({
    method: 'get',
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
    url: `/v2/client-users/is-email-known?email=${encodeURIComponent(email)}`,
    isAuthenticated: false,
  });

  return data;
};

export const recoverPassword = async (email: string): Promise<void> => {
  const { data } = await api<void>({
    method: 'post',
    url: '/v2/authentication/recover-password',
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
    body: { email },
    isAuthenticated: false,
  });

  return data;
};

export const resetPassword = async (body: {
  token: string;
  password: string;
  confirmation: string;
}) => {
  const { data } = await api<void>({
    method: 'put',
    url: '/v2/authentication/password/reset',
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
    body,
    isAuthenticated: false,
  });

  return data;
};

export const updatePassword = async (body: {
  password: string;
  newPassword: string;
  confirmation: string;
}) => {
  const { data } = await api<string>({
    method: 'put',
    url: '/v2/authentication/password',
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
    body,
  });

  return data;
};

export const getAccessControlMatrix = async (companyId: string) => {
  const { data } = await api<Array<AccessControlMatrix>>({
    method: 'get',
    url: `/v2/companies/${companyId}/access-control-matrix`,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
  });

  return data;
};

export const getBolderAccessControlMatrices = async () => {
  const { data } = await api<Array<BolderAccessControlMatrixEntity>>({
    method: 'get',
    url: '/v2/bolders/access-control-matrix',
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
  });

  return data;
};

export const getPrivileges = async () => {
  const { data } = await api<PrivilegesEntity>({
    method: 'get',
    url: '/v2/privileges',
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
  });

  return data.privileges;
};

export const getPrivilegesOperationEventTypes = async () => {
  const { data } = await api<OperationEventTypesPrivilegesEntity>({
    method: 'get',
    url: '/v2/privileges/operation-event-types',
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
  });

  return data.operationEventTypesPrivileges;
};

export const getGoogleAuthUrl = async () => {
  const { data } = await api<string>({
    method: 'get',
    url: '/v2/authentication/google-auth',
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
  });

  return data;
};

export const authenticateAndGetBolder = async (code: string) => {
  const { status, data } = await api<BolderEntity & { token: string }>({
    method: 'get',
    url: `/v2/authentication/google-auth/callback${toQueryString({
      code,
    })}`,
    origin: process.env.NEXT_PUBLIC_V2_API_URL,
  });

  return { ...data, status };
};
