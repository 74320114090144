import Cookies from 'js-cookie';

export enum Roles {
  Admin = 'admin',
  Bolder = 'bolder',
  ClientUser = 'client_user',
}

const getUserRole = (): Roles => Cookies.get('role');

const isBolder = (): boolean => {
  return getUserRole() === Roles.Bolder;
};

const isAdmin = (): boolean => {
  return getUserRole() === Roles.Admin;
};

const isClientUser = (): boolean => {
  return getUserRole() === Roles.ClientUser;
};

export { getUserRole, isBolder, isAdmin, isClientUser };
